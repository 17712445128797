import { Component, Vue } from 'vue-property-decorator'
import DocumentPreview from '@/components/shared/DocumentPreview/FactureBase/FactureBase.vue'
import { ValidationObserver } from 'vee-validate'
import { InvoiceModel } from '@/models/invoice-models'
import { InvoiceService } from '@/services/invoice-service'
import { ErrorService } from '@/services/error.service'
import InvoiceHelpers from '@/components/shared/Helpers/InvoiceHelpers'
import Commons from '@/components/shared/Helpers/commons'
import Alert from '@/components/shared/Alert.vue'
import PdfHelpers from '@/components/shared/Helpers/PdfHelpers'
import { InvoiceDocumentType } from '../Constants'

@Component({
  components: {
    Alert,
    DocumentPreview,
    ValidationObserver
  }
})
export default class Facture extends Vue {
  private invoiceService = InvoiceService.getInstance()
  public invoice?: InvoiceModel = { invoiceItems: [], vatItems: [] }
  public errorMessages: string[] = []
  public downloading = false
  public documentType = InvoiceDocumentType.Invoice

  public mounted () {
    const invoiceId = parseInt(this.$route.params.id, 10)
    this.documentType = parseInt(this.$route.params.documentType, 10)
    if (isNaN(invoiceId)) {
      this.errorMessages = ['Impossible de déterminer le numéro de la facture à visualiser.']
    } else {
      this.invoiceService.getInvoice(invoiceId).then(invoice => {
        this.invoice = invoice
      }).catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        this.errorMessages = res.errors
      })
    }
  }

  public async downloadPDF () {
    this.downloading = true
    const documentPreview = this.$refs.documentpreview as any
    const model = InvoiceHelpers.GeneratePDFModel(`Facture-${this.invoice?.id}-${this.invoiceDate}.pdf`, documentPreview.$refs)
    this.invoiceService.downloadPdf(model).then(f => {
      PdfHelpers.downloadFile(f)
    }).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      this.errorMessages = res.errors
    }).finally(() => {
      this.downloading = false
    })
  }

  public get invoiceDate () {
    switch (this.documentType) {
      case InvoiceDocumentType.InvoiceReminder:
        return Commons.TransformDateFormat(this.invoice?.dateReminder)
      case InvoiceDocumentType.NoteCredit:
        return Commons.TransformDateFormat(this.invoice?.noteCredit?.date)
      default:
        return Commons.TransformDateFormat(this.invoice?.date)
    }
  }

  public goBack () {
    this.$router.push({ name: 'GeneratedInvoices', params: { replay: JSON.stringify(true) } })
  }

  public hideAlert () {
    this.errorMessages = []
  }
}
